import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/home.css";
import {
  GetUserStatusURL,
  GetUserURL,
  GetVideoCountURL,
  GetPlanDetailsURL,
} from "../assest/api/BaseURL";
import SideBar from "../SideBar";
import NavBar from "../NavBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpgradePage = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [selectedValue, setSelectedValue] = useState("monthly");
  const [videoCount, setVideoCount] = useState(0);
  const [planDetails, setPlanDetails] = useState([]);
  const [plan, setPlan] = useState({});
  const [userStatus, setUserStatus] = useState("");
  const [isLoading, setIsloading] = useState(true);
  const [user, setUser] = useState({
    full_name: "",
    email: "",
    country_code: null,
    contact_no: null,
    state: null,
    pincode: null,
    address: null,
    profile_pic: null,
  });

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleUpgradeClick = () => {
    navigate(`/upgrade-type`);
  };

  const showToast = (type, message) => {
    if (type === "error") {
      toast.error(message);
    }

    if (type === "success") {
      toast.success(message);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (token) {
      async function getUser() {
        try {
          const response = await axios.get(GetUserURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setUser(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
      getUser();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const getVideosCount = async () => {
        try {
          const response = await axios.get(GetVideoCountURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setVideoCount(response.data.data.count);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getVideosCount();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      async function getPlanDetails() {
        try {
          const response = await axios.get(GetPlanDetailsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setPlanDetails(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
      getPlanDetails();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const getUserStatus = async () => {
        try {
          const response = await axios.get(GetUserStatusURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setUserStatus(response.data.data.status);
            setIsloading(false);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getUserStatus();
    }
  }, [token]);

  useEffect(() => {
    if (selectedValue && planDetails) {
      const data = planDetails.filter(
        (item) => item.planDetailsFeatureFlag === selectedValue
      );
      setPlan(data[0]);
    }
  }, [selectedValue, planDetails]);

  return (
    <>
      <div className="background_img">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-collapse" uk-grid="">
              <SideBar active="upgrade" token={token} showToast={showToast} />
              <div className="uk-width-expand">
                <NavBar
                  user={user}
                  token={token}
                  showToast={showToast}
                  videoCount={videoCount}
                />

                {isLoading ? (
                  <div className="content">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div uk-spinner="ratio: 3"></div>
                    </div>
                  </div>
                ) : (
                  <div className="content">
                    <div className="contentdiv">
                      <div className="uk-grid" uk-grid="">
                        <div className="uk-width-expand@m">
                          <h3 className="ukhd">All Plans</h3>
                        </div>
                        {userStatus === "inactive" && (
                          <div className="uk-width-auto@m uk-text-right">
                            <form>
                              <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                <label>
                                  <input
                                    className="uk-radio"
                                    type="radio"
                                    value="monthly"
                                    checked={selectedValue === "monthly"}
                                    onChange={handleRadioChange}
                                  />{" "}
                                  Billed Monthly
                                </label>
                                <label>
                                  <input
                                    className="uk-radio"
                                    type="radio"
                                    value="yearly"
                                    checked={selectedValue === "yearly"}
                                    onChange={handleRadioChange}
                                  />{" "}
                                  Billed Annually
                                </label>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                      <div
                        className="uk-grid-small uk-grid uk-grid-match pricing_section"
                        uk-grid=""
                      >
                        <div className="uk-width-1-2@m">
                          <div className="uk-card uk-card-default uk-card-body uk-border-rounded pcard">
                            <div className="customhd">
                              <h3>Starter</h3>
                              <h4>Quick videos recording</h4>
                            </div>

                            <h2 className="pricehd">Free</h2>

                            <h6 className="price_subhd">Workspace Limits</h6>
                            <ul className="uk-list pricelist">
                              <li>
                                <i className="fa fa-check"></i> 25 videos
                              </li>
                              <li>
                                <i className="fa fa-check"></i> 10 minutes
                                recording length
                              </li>
                            </ul>

                            {userStatus === "inactive" && (
                              <h6 className="price_subhd  selected">
                                Your Plan
                              </h6>
                            )}
                          </div>
                        </div>

                        <div className="uk-width-1-2@m">
                          <div className="uk-card uk-card-default uk-card-body uk-border-rounded pcard">
                            <div className="customhd">
                              <h3>{plan && plan.planDetailsName}</h3>
                              <h4>Advanced videos recording</h4>
                            </div>

                            <h2 className="pricehd">
                              ${plan && plan.planPerMonthAmount / 100}
                              <span className="pricetag">per month</span>
                            </h2>

                            <h6 className="price_subhd">Workspace Limits</h6>
                            <ul className="uk-list pricelist">
                              <li>
                                <i className="fa fa-check"></i> 25 videos
                              </li>
                              <li>
                                <i className="fa fa-check"></i>
                                {plan && plan.planDetailsDuration} minutes
                                recording length
                              </li>
                            </ul>

                            {userStatus === "active" ? (
                              <>
                                <h6 className="price_subhd  selected">
                                  Your Plan
                                </h6>
                                <p>
                                  Note : If you want to change your plan then
                                  contact us on{" "}
                                  <span>operations@iwcnetwork.com</span>
                                </p>
                              </>
                            ) : (
                              <h6 className="price_subhd  uk-text-center">
                                <button
                                  type="submit"
                                  className="uk-button uk-button-default setting_custombtn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleUpgradeClick();
                                  }}
                                >
                                  {" "}
                                  Upgrade Plan
                                </button>
                              </h6>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default UpgradePage;
