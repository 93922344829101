import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/home.css";
import {
  DeleteAccountURL,
  GetUserURL,
  UpdatePasswordURL,
  UpdateProfileURL,
  UploadProfilePicURL,
  GetVideoCountURL,
} from "../assest/api/BaseURL";
import SideBar from "../SideBar";
import NavBar from "../NavBar";
import ProfilePic from "../../images/profile.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../DeleteModal";

const SettingPage = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [user, setUser] = useState({
    full_name: "",
    email: "",
    country_code: null,
    contact_no: null,
    state: null,
    pincode: null,
    address: null,
    profile_pic: null,
  });
  const [password, setPassword] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const [videoCount, setVideoCount] = useState(0);

  const handlePasswordVisible = (e) => {
    e.preventDefault();
    setVisiblePassword(!visiblePassword);
  };

  const handleConfirmPasswordVisible = (e) => {
    e.preventDefault();
    setVisibleConfirmPassword(!visibleConfirmPassword);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleInput = (event) => {
    setUser((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleProfilePic = async (event) => {
    const file = event.target.files[0];

    let fileSize = file.size / 1048576;

    if (fileSize > 2) {
      toast.error("File size should not be more than 2 Mb");
    } else {
      const data = new FormData();
      data.append("profile_pic", event.target.files[0]);
      try {
        const response = await axios({
          method: "post",
          url: UploadProfilePicURL,
          data: data,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setUser((prev) => ({
            ...prev,
            profile_pic: response.data.data.PicURl,
          }));
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
        console.log(error);
      }
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    if (user.full_name === "" || user.full_name == null) {
      toast.error("Name should not be empty");
    } else {
      try {
        const response = await axios({
          method: "put",
          url: UpdateProfileURL,
          data: user,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
          
        if (response.status === 200) {
          toast.success(response.data.message);
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
        console.log(error);
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (password === "") {
      toast.error("Password should not be empty");
    } else if (password.length < 4) {
      toast.error("Password must be have minimum 4 characters");
    } else if (confirmPassword === "") {
      toast.error("Confirm Password should not be empty");
    } else if (password !== confirmPassword) {
      toast.error("Password and Confirm Password must be same");
    } else {
      try {
        const response = await axios({
          method: "put",
          url: UpdatePasswordURL,
          data: { password: password, confirmPassword: confirmPassword },
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          toast.success(response.data.message);
          setPassword("");
          setConfirmPassword("");
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
          setPassword("");
          setConfirmPassword("");
        } else {
          toast.error(error.message);
          setPassword("");
          setConfirmPassword("");
        }
        console.log(error);
      }
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteAccountURL,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setIsModal(!isModal);
        localStorage.removeItem("token");
        navigate("/");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
      } else {
        alert(error.message);
      }
      console.log(error);
    }
  };

  const showToast = (type, message) => {
    if (type === "error") {
      toast.error(message);
    }

    if (type === "success") {
      toast.success(message);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (token) {
      async function getUser() {
        try {
          const response = await axios.get(GetUserURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setUser(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
      getUser();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const getVideosCount = async () => {
        try {
          const response = await axios.get(GetVideoCountURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setVideoCount(response.data.data.count);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getVideosCount();
    }
  }, [token]);

  return (
    <>
      <div className="background_img">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-collapse" uk-grid="">
              <SideBar active="setting" token={token} showToast={showToast} />
              <div className="uk-width-expand">
                <NavBar
                  user={user}
                  token={token}
                  showToast={showToast}
                  videoCount={videoCount}
                />

                <div className="content">
                  <div className="contentdiv">
                    <div className="uk-width-1-1@s uk-text-right">
                      <button
                        className="uk-button uk-button-default setting_custombtn dangerbtn"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsModal(!isModal);
                        }}
                      >
                        <i className="fa fa-trash"></i>&nbsp;Delete My Account
                      </button>
                    </div>
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand@m">
                        <h3 style={{ fontSize: "1.2rem" }}>Profile</h3>
                        <div className="uk-card uk-card-body uk-card-default">
                          <form className="uk-grid-small uk-grid" uk-grid="">
                            <div className="uk-width-1-1@s uk-grid-margin">
                              <div className="uk-margin">
                                <label className="uk-form-label">
                                  Name <span className="uk-text-danger">*</span>
                                </label>
                                <div className="uk-form-controls">
                                  <input
                                    className="uk-input"
                                    name="full_name"
                                    type="text"
                                    placeholder="Enter First Name"
                                    value={
                                      user.full_name == null
                                        ? ""
                                        : user.full_name
                                    }
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="uk-width-1-1@s uk-grid-margin">
                              <div className="uk-margin">
                                <label className="uk-form-label">
                                  Email{" "}
                                  <span className="uk-text-danger">*</span>
                                </label>
                                <div className="uk-form-controls">
                                  <input
                                    className="uk-input"
                                    name="email"
                                    type="email"
                                    readOnly={true}
                                    placeholder="Enter Email"
                                    value={user.email == null ? "" : user.email}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="uk-width-1-1@s uk-grid-margin">
                              <div className="uk-margin">
                                <label className="uk-form-label">
                                  Address{" "}
                                </label>
                                <div className="uk-form-controls">
                                  <textarea
                                    className="uk-textarea"
                                    name="address"
                                    placeholder="Enter Address"
                                    value={
                                      user.address == null ? "" : user.address
                                    }
                                    onChange={handleInput}
                                  ></textarea>
                                </div>
                              </div>
                            </div>

                            <div className="uk-width-1-1@s uk-grid-margin">
                              <div className="uk-grid" uk-grid="">
                                <div className="uk-width-auto@m">
                                  {user.profile_pic ? (
                                    <img
                                      src={user.profile_pic}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "50%",
                                      }}
                                      alt="Profile Pic"
                                    />
                                  ) : (
                                    <img
                                      src={ProfilePic}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      alt="Profile Pic"
                                    />
                                  )}
                                </div>
                                <div className="uk-width-expand@m valign">
                                  <div className="uk-margin">
                                    <label className="uk-form-label">
                                      Update Profile Image
                                    </label>
                                    <div className="uk-form-controls uk-margin-small-top">
                                      <input
                                        name="profile_pic"
                                        type="file"
                                        onChange={handleProfilePic}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="uk-width-1-1@s uk-grid-margin">
                              <button
                                type="submit"
                                className="uk-button uk-button-default setting_custombtn"
                                name="submit_profile"
                                onClick={handleUpdateProfile}
                              >
                                <i className="fa fa-save"></i>&nbsp; Update
                                Profile
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="uk-width-large@m">
                        <h3 style={{ fontSize: "1.2rem" }}>Change Password</h3>
                        <div className="uk-card uk-card-body uk-card-default">
                          <form
                            className="uk-grid-small uk-grid uk-grid-stack"
                            uk-grid=""
                          >
                            <div className="uk-width-1-1@s uk-grid-margin">
                              {/* <div className="uk-margin">
                                <label className="uk-form-label">
                                  New Password{" "}
                                  <span className="uk-text-danger">*</span>
                                </label>
                                <div className="uk-form-controls">
                                  <input
                                    className="uk-input"
                                    name="newpassword"
                                    type="password"
                                    placeholder="Enter New Password"
                                  />
                                </div>
                              </div> */}
                              <div className="uk-margin">
                                <label className="uk-form-label">
                                  New Password{" "}
                                  <span className="uk-text-danger">*</span>
                                </label>
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-expand@m">
                                    <div className="uk-form-controls">
                                      <input
                                        className="uk-input"
                                        type={
                                          visiblePassword ? "text" : "password"
                                        }
                                        name="password"
                                        placeholder="Enter New Password"
                                        value={password}
                                        onChange={handlePassword}
                                      />
                                    </div>
                                  </div>
                                  <div className="uk-width-auto@m">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={handlePasswordVisible}
                                    >
                                      {visiblePassword ? (
                                        <i className="fa fa-eye-slash logineye"></i>
                                      ) : (
                                        <i className="fa fa-eye logineye"></i>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="uk-width-1-1@s uk-grid-margin">
                              {/* <div className="uk-margin">
                                <label className="uk-form-label">
                                  Confirm Password{" "}
                                  <span className="uk-text-danger">*</span>
                                </label>
                                <div className="uk-form-controls">
                                  <input
                                    className="uk-input"
                                    name="confirmpassword"
                                    type="password"
                                    placeholder="Enter Confirm Password"
                                  />
                                </div>
                              </div> */}
                              <div className="uk-margin">
                                <label className="uk-form-label">
                                  Confirm Password{" "}
                                  <span className="uk-text-danger">*</span>
                                </label>
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-expand@m">
                                    <div className="uk-form-controls">
                                      <input
                                        className="uk-input"
                                        type={
                                          visibleConfirmPassword
                                            ? "text"
                                            : "password"
                                        }
                                        name="confirmPassword"
                                        placeholder="Enter Confirm Password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPassword}
                                        fdprocessedid="0ktsw"
                                      />
                                    </div>
                                  </div>
                                  <div className="uk-width-auto@m">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={handleConfirmPasswordVisible}
                                    >
                                      {visibleConfirmPassword ? (
                                        <i className="fa fa-eye-slash logineye"></i>
                                      ) : (
                                        <i className="fa fa-eye logineye"></i>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="uk-width-1-1@s uk-grid-margin">
                              <button
                                className="uk-button uk-button-default setting_custombtn"
                                onClick={handleResetPassword}
                              >
                                <i className="fa fa-lock"></i>&nbsp;Change
                                Password
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModal && (
        <DeleteModal
          Info="Account"
          handleDelete={handleDeleteAccount}
          handleModal={setIsModal}
        />
      )}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default SettingPage;
