import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/home.css";
import SideBar from "../SideBar";
import NavBar from "../NavBar";
import LinkImg from "../../images/web/blink.png";
import MoreImg from "../../images/web/bmore.png";
import {
  GetUserURL,
  DeleteVideoURL,
  GetRecordedVideosURL,
  ShareLinkURL,
  GetVideoCountURL,
} from "../assest/api/BaseURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../DeleteModal";

const HomePage = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [recordedVideos, setRecordedVideos] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [isLoading, setIsloading] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [videoCount, setVideoCount] = useState(0);
  const [user, setUser] = useState({
    full_name: "",
    email: "",
    country_code: null,
    contact_no: null,
    state: null,
    pincode: null,
    address: null,
    profile_pic: null,
  });

  const handleVideoClick = (id) => {
    navigate(`/video/${id}`);
  };

  const handleCopy = (id) => {
    const videoUrl = `${ShareLinkURL}/video/${id}`;
    navigator.clipboard
      .writeText(videoUrl)
      .then(() => toast.success("Link Copied Successfully !"))
      .catch((error) => console.error("Failed to copy video URL:", error));
  };

  const handleDownload = async (url, fileName) => {
    const videoUrl = url;

    try {
      const response = await axios.get(videoUrl, { responseType: "blob" });
      const videoBlob = new Blob([response.data]);

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(videoBlob);
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading video:", error);
    }
  };

  const deleteVideo = (id) => {
    setDeleteId(id);
    setIsModal(!isModal);
  };

  const handleDelete = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteVideoURL + `?video_id=${deleteId}`,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        await getRecordedVideosData();
        setIsModal(!isModal);
        toast.success("Video deleted Successfully !");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
      } else {
        alert(error.message);
      }
      console.log(error);
    }
  };

  const getRecordedVideosData = async () => {
    try {
      const response = await axios.get(GetRecordedVideosURL, {
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setRecordedVideos(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showToast = (type, message) => {
    if (type === "error") {
      toast.error(message);
    }

    if (type === "success") {
      toast.success(message);
    }    
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (token) {
      async function getUser() {
        try {
          const response = await axios.get(GetUserURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setUser(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
      getUser();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const getRecordedVideosData = async () => {
        try {
          const response = await axios.get(GetRecordedVideosURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            if (response.data.data) {
              setRecordedVideos(response.data.data);
            }
            setIsloading(false);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getRecordedVideosData();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const getVideosCount = async () => {
        try {
          const response = await axios.get(GetVideoCountURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setVideoCount(response.data.data.count);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getVideosCount();
    }
  }, [token, isModal]);

  return (
    <>
      <div className="background_img">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-collapse" uk-grid="">
              <SideBar active="dashboard" token={token} showToast={showToast} />
              <div className="uk-width-expand">
                <NavBar
                  user={user}
                  token={token}
                  showToast={showToast}
                  videoCount={videoCount}
                />

                {isLoading ? (
                  <div className="content">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div uk-spinner="ratio: 3"></div>
                    </div>
                  </div>
                ) : (
                  <div className="content">
                    <ul uk-tab="" className="custom_tab">
                      <li>
                        <span>Videos</span>
                      </li>
                    </ul>

                    {recordedVideos && recordedVideos.length === 0 ? (
                      <ul className="fswitcher uk-margin">
                        <div className="uk-margin">
                          <h2>No video recorded yet !</h2>
                        </div>
                      </ul>
                    ) : (
                      <ul className="fswitcher uk-margin">
                        <li>
                          <ul className="sswitcher uk-margin">
                            <li>
                              <div className="gridview">
                                <div
                                  className="uk-child-width-1-4@xl uk-child-width-1-3@l uk-child-width-1-2@m  uk-child-width-1-2@s  uk-grid-large"
                                  uk-grid=""
                                >
                                  {recordedVideos &&
                                    recordedVideos.map((item) => (
                                      <div key={item.video_id}>
                                        <div className="uk-card uk-card-default grid_card">
                                          <div className="uk-card-media-top">
                                            <video
                                              className="gridimg"
                                              src={item.file_url}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleVideoClick(item.video_id);
                                              }}
                                            ></video>
                                          </div>
                                          <div className="uk-card-body">
                                            <div className="" uk-grid="">
                                              <div className="uk-width-expand">
                                                <div
                                                  className="uk-grid-small uk-flex-middle uk-grid"
                                                  uk-grid=""
                                                >
                                                  <div className="uk-width-expand uk-text-left">
                                                    <p className="sample">
                                                      {" "}
                                                      <b>
                                                        {item.original_fileName}
                                                      </b>
                                                      <br />
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="alright uk-width-auto">
                                                <div>
                                                  <img
                                                    src={LinkImg}
                                                    alt="LinkImg"
                                                    className="mr20"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handleCopy(item.video_id);
                                                    }}
                                                  />
                                                  <div className="uk-inline">
                                                    <span>
                                                      <img
                                                        src={MoreImg}
                                                        alt="MoreImg"
                                                        className=""
                                                      />
                                                    </span>
                                                    <div
                                                      uk-dropdown="mode: click;pos: top-right"
                                                      className="dotdropw uk-dropdown uk-dropdown-bottom-right"
                                                    >
                                                      <ul className="uk-nav uk-dropdown-nav uk-text-left dotdrop">
                                                        <li className="uk-active">
                                                          <span
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              handleDownload(
                                                                item.file_url,
                                                                item.file_name
                                                              );
                                                            }}
                                                          >
                                                            Download
                                                          </span>
                                                        </li>
                                                        <li className="uk-active">
                                                          <span
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              deleteVideo(
                                                                item.video_id
                                                              );
                                                            }}
                                                          >
                                                            Delete
                                                          </span>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModal && (
        <DeleteModal
          Info="Video"
          handleDelete={handleDelete}
          handleModal={setIsModal}
        />
      )}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default HomePage;
