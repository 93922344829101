import React from 'react'

const DeleteModal = ({ Info, handleDelete, handleModal }) => {
  return (
    <>
      <div
        className="uk-open"
        style={{ display: "block" }}
        uk-modal="bg-close:false;"
      >
        <div className="uk-modal-dialog">
          <div className="uk-modal-header">
            <h3 className="">Delete {Info}</h3>
          </div>
          <form className="uk-form-stacked" encType="multipart/form-data">
            <div className="uk-modal-body">
              <span className="success_msg"></span>

              <div className="uk-width-1-1@s uk-text-center">
                <h5>
                  Are you sure ? You want to delete this {Info} (This action
                  will delete {Info} permanently from every where) .
                </h5>
              </div>
            </div>

            <div className="uk-modal-footer uk-text-right">
              <button
                className="uk-button uk-button-danger setting_custombtn dangerbtn"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
              >
                <span
                  uk-icon="icon: trash; ratio: 0.8"
                  className="uk-icon"
                ></span>{" "}
                Delete {Info}
              </button>
              &nbsp;&nbsp;
              <button
                className="uk-button uk-button-default cancelbtn setting_custombtn"
                type="button"
                onClick={() => handleModal(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DeleteModal