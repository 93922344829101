import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LOGO from "../images/newlogo.png";
import RecordingModal from "./RecordingModal";
import { LogoutURL, GetVideoCountURL } from "./assest/api/BaseURL";

const SideBar = ({ active, token, showToast }) => {
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false);
  const [videoCount, setVideoCount] = useState(0);

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(LogoutURL, {
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        localStorage.removeItem("token");
        navigate("/");
      }
    } catch (error) {
      alert(error.message);
      navigate("/");
      console.log(error);
    }
  };

  const handleRecordNowClick = (e) => {
    e.preventDefault();
    if (videoCount < 25) {
      setIsModal(true);
    } else {
      showToast("error", "Video Limit Exceeded !");
    }
  };

  useEffect(() => {
    if (token) {
      const getVideosCount = async () => {
        try {
          const response = await axios.get(GetVideoCountURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setVideoCount(response.data.data.count);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getVideosCount();
    }
  }, [token]);

  return (
    <>
      <div className="sidebar uk-visible@m">
        <div className="logodiv">
          <span
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard");
            }}
          >
            <img src={LOGO} alt="Logo" className="logo" />{" "}
            <span className="sceerotxt">Sceero</span>
          </span>
        </div>

        <div className="record">
          <button
            className="uk-button recordbtn"
            onClick={handleRecordNowClick}
          >
            Record Now
          </button>
        </div>

        <div className="menu">
          <ul className="uk-list menuli">
            <li
              className={active === "dashboard" ? "currentli" : ""}
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard");
              }}
            >
              <i className="fa fa-image pdr7" aria-hidden="true"></i> Gallery
            </li>
            <li
              className={active === "setting" ? "currentli" : ""}
              onClick={(e) => {
                e.preventDefault();
                navigate("/setting");
              }}
            >
              <i className="fa fa-cogs pdr7" aria-hidden="true"></i> Setting
            </li>
            <li
              className={active === "upgrade" ? "currentli" : ""}
              onClick={(e) => {
                e.preventDefault();
                navigate("/upgrade");
              }}
            >
              <i className="fa fa-ticket pdr7" aria-hidden="true"></i> Upgrade
            </li>
            <li onClick={handleLogout}>
              <i className="fa fa-power-off pdr7" aria-hidden="true"></i> Logout
            </li>
          </ul>
        </div>
      </div>
      {isModal && <RecordingModal token={token} handleModal={setIsModal} />}
    </>
  );
};

export default SideBar;
