import React from "react";
import ErrorImg from "../../images/404_Error.jpg";
import { Link, useNavigate } from "react-router-dom";
import "../styles/invideo.css";
import Logo from "../../images/sceero_logo.png";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/");
  };

  const handleSignupClick = () => {
    navigate("/signup");
  };

  return (
    <>
      <div className="background">
        <div className="firstbck uk-visible@m shadowcss">
          <nav className="firsthd" uk-navbar="">
            <div className="uk-navbar-left">
              <div className="uk-navbar-item logopd">
                <span>
                  <span className="navbar-brand">
                    <img src={Logo} alt="Logo" className="sceero_logo" />{" "}
                    <span className="logotxt">Sceero</span>
                  </span>
                </span>
              </div>
            </div>
            <div className="uk-navbar-center"></div>
            <div className="uk-navbar-right">
              <div className="uk-navbar-item">
                <div>
                  <ul className="uk-navbar-nav">
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        handleLoginClick();
                      }}
                    >
                      <span className="uk-button custombtn header_nav">
                        {" "}
                        Login
                      </span>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        handleSignupClick();
                      }}
                    >
                      <span className="uk-button custombtn header_nav">
                        {" "}
                        Sign Up
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>

        <div style={{ textAlign: "center" }}>
          <div>
            <img
              src={ErrorImg}
              style={{
                width: "500px",
                height: "500px",
                mixBlendMode: "multiply",
              }}
              alt="ErrorImg"
            />
          </div>
          <h2 style={{ margin: "0" }}>
            <b>You weren't supposed to see this.</b>
          </h2>
          <h3 style={{ margin: "0" }}>Sorry, but this page doesn't exist.</h3>
          <Link to="/dashboard">
            <h4 style={{ margin: "0" }}>
              <span>Go to Homepage</span>
            </h4>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
