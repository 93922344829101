import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ProfilePic from "../images/profile.svg";
import RecordingModal from "./RecordingModal";
import { LogoutURL, GetRecordedVideosURL } from "./assest/api/BaseURL";

const NavBar = ({ user, token, showToast, videoCount }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [recordedVideos, setRecordedVideos] = useState([]);
  const [searchVideoData, setSearchVideoData] = useState([]);
  const [isModal, setIsModal] = useState(false);

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(LogoutURL, {
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        localStorage.removeItem("token");
        navigate("/");
      }
    } catch (error) {
      alert(error.message);
      navigate("/");
      console.log(error);
    }
  };

  const handleRecordNowClick = (e) => {
    e.preventDefault();
    if (videoCount < 25) {
      setIsModal(true);
    } else {
      showToast("error", "Video Limit Exceeded !");
    }
  };

  const handleVideoClick = (id) => {
    navigate(`/video/${id}`);
  };

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 0) {
      const data = recordedVideos.filter((item) => {
        return item.original_fileName
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setSearchVideoData(data);
    }
  };

  useEffect(() => {
    if (token) {
      const getRecordedVideosData = async () => {
        try {
          const response = await axios.get(GetRecordedVideosURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            if (response.data.data) {
              setRecordedVideos(response.data.data);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      getRecordedVideosData();
    }
  }, [token]);

  return (
    <>
      <div className="navdiv">
        <div className="uk-grid navpd" uk-grid="">
          <div className="uk-width-expand">
            <form className="uk-search uk-search-default">
              <span uk-search-icon=""></span>
              <input
                className="uk-search-input search_input"
                type="search"
                placeholder="Search in Sceero"
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
              {search.length > 0 && (
                <div
                  className="uk-navbar-dropdown uk-open searhdrop"
                  style={{ width: "100%" }}
                >
                  <ul className="uk-nav uk-dropdown-nav dotdrop">
                    {search.length > 0 && searchVideoData.length !== 0 ? (
                      <>
                        {searchVideoData.slice(0, 3).map((item) => (
                          <li key={item.video_id}>
                            <div
                              className="uk-child-width-expand@s"
                              uk-grid=""
                              onClick={(e) => {
                                e.preventDefault();
                                handleVideoClick(item.video_id);
                              }}
                            >
                              <div>
                                <div className="submenu">
                                  {item.original_fileName}
                                </div>
                              </div>
                              <div className="valign">
                                <div className="wrk">Video</div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </>
                    ) : (
                      <>
                        <li>
                          <div className="uk-child-width-expand@s" uk-grid="">
                            <div>
                              <div className="submenu">No Results Found</div>
                            </div>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </form>
          </div>
          <button
            className="uk-button custombtn"
            style={{ marginLeft: "30px" }}
            onClick={handleRecordNowClick}
          >
            Record
          </button>
          <div className="topwidth valign alright">
            <div className="uk-inline">
              <span className="uk-text-success bell">
                {videoCount}/25 videos
              </span>
            </div>

            <span>
              <div className="uk-inline">
                <span>
                  {" "}
                  {user.profile_pic ? (
                    <img
                      src={user.profile_pic}
                      alt="ProfilePic"
                      className="profile_pic"
                    />
                  ) : (
                    <img
                      src={ProfilePic}
                      alt="ProfilePic"
                      className="profile_pic"
                    />
                  )}
                </span>
                <div
                  uk-dropdown="mode: click;pos: top-right"
                  className="profile_dropw uk-dropdown uk-dropdown-bottom-right"
                >
                  <ul className="uk-nav uk-dropdown-nav uk-text-left dotdrop">
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/setting");
                      }}
                    >
                      <span>Setting</span>
                    </li>
                    <li onClick={handleLogout}>
                      <span>Sign out</span>
                    </li>
                  </ul>
                </div>
              </div>
            </span>
            <span className="person_name">{user.full_name}</span>
          </div>
        </div>
      </div>
      {isModal && <RecordingModal token={token} handleModal={setIsModal} />}
    </>
  );
};

export default NavBar;
