import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";
import ForgotPage from "./components/pages/ForgotPage";
import HomePage from "./components/pages/HomePage";
import ResetPage from "./components/pages/ResetPage";
import VideoPage from "./components/pages/VideoPage";
import ShareVideoPage from "./components/pages/ShareVideoPage";
import SettingPage from "./components/pages/SettingPage";
import UpgradePage from "./components/pages/UpgradePage";
import NotFoundPage from "./components/pages/NotFoundPage";
import UpgradeTypePage from "./components/pages/UpgradeTypePage";
import PaymentPage from "./components/pages/PaymentPage";
import ExtensionPage from "./components/pages/ExtensionPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/signup" element={<Signup />}></Route>
            <Route path="/forgot_password" element={<ForgotPage />}></Route>
            <Route
              path="/reset_password/:token"
              element={<ResetPage />}
            ></Route>
            <Route path="/dashboard" element={<HomePage />}></Route>
            <Route path="/setting" element={<SettingPage />}></Route>
            <Route path="/upgrade" element={<UpgradePage />}></Route>
            <Route path="/upgrade-type" element={<UpgradeTypePage />}></Route>
            <Route path="/upgrade-type/:type" element={<PaymentPage />}></Route>
            <Route path="/video/:video_id" element={<VideoPage />}></Route>
            <Route path="/record" element={<ExtensionPage />}></Route>
            <Route
              path="/share_video/:video_id"
              element={<ShareVideoPage />}
            ></Route>
            <Route path="*" element={<NotFoundPage />}></Route>
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
