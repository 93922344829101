import React from 'react'

const Loader = () => {
  return (
    <>
      <div
        className="uk-open"
        style={{ display: "block" }}
        uk-modal="bg-close:false;"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "100px",
            padding: 0,
          }}
        >
          <div uk-spinner="ratio: 3"></div>
        </div>
      </div>
    </>
  );
}

export default Loader