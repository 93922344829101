import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../styles/invideo.css";
import Logo from "../../images/sceero_logo.png";
import LinkImg from "../../images/link.png";
import { GetShareVideoInfoURL, ShareLinkURL } from "../assest/api/BaseURL";

const ShareVideoPage = () => {
  const navigate = useNavigate();
  const { video_id } = useParams();
  const [videoInfo, setVideoInfo] = useState({});
  const [videoName, setVideoName] = useState("");

  const handleCopy = () => {
    const videoUrl = `${ShareLinkURL}/video/${video_id}`;
    navigator.clipboard
      .writeText(videoUrl)
      .then(() => alert("Link Copied"))
      .catch((error) => console.error("Failed to copy video URL:", error));
  };

  const handleLoginClick = () => {
    navigate("/");
  };

  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleDownload = async () => {
    const videoUrl = videoInfo.file_url;
    const fileName = videoInfo.file_name;

    try {
      const response = await axios.get(videoUrl, { responseType: "blob" });
      const videoBlob = new Blob([response.data]);

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(videoBlob);
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading video:", error);
    }
  };

  useEffect(() => {
    const getVideoData = async () => {
      try {
        const response = await axios.get(
          GetShareVideoInfoURL + `?video_id=${video_id}`
        );

        if (response.status === 200 && response.data.data) {
          setVideoInfo(response.data.data);
          setVideoName(response.data.data.original_fileName);
        } else {
          navigate("*");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVideoData();
  }, [navigate, video_id]);

  return (
    <>
      <div className="background">
        <div className="firstbck uk-visible@m shadowcss">
          <nav className="firsthd" uk-navbar="">
            <div className="uk-navbar-left">
              <div className="uk-navbar-item logopd">
                <span>
                  <span className="navbar-brand">
                    <img src={Logo} alt="Logo" className="sceero_logo" />{" "}
                    <span className="logotxt">Sceero</span>
                  </span>
                </span>
              </div>
            </div>
            <div className="uk-navbar-center"></div>
            <div className="uk-navbar-right">
              <div className="uk-navbar-item">
                <div>
                  <ul className="uk-navbar-nav">
                    <li className="">
                      {" "}
                      <span
                        className="header_nav"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCopy();
                        }}
                      >
                        <img
                          src={LinkImg}
                          alt="LinkImg"
                          style={{ padding: "8px" }}
                        />
                      </span>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        handleLoginClick();
                      }}
                    >
                      <span className="uk-button custombtn header_nav">
                        {" "}
                        Login
                      </span>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        handleSignupClick();
                      }}
                    >
                      <span className="uk-button custombtn header_nav">
                        {" "}
                        Sign Up
                      </span>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        handleDownload();
                      }}
                    >
                      <span className="uk-button btnprimary header_nav">
                        {" "}
                        Download
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>

        {videoInfo && (
          <div className="share_container">
            <div className="uk-width-1-1 mt3rem pl-pr3rem mb1rem">
              <input
                className="uk-input inputcss"
                type="text"
                placeholder="Lorem Ipsum"
                readOnly={true}
                value={videoName}
              />
            </div>

            <div className="uk-width-1-1 uk-text-center">
              <div className="uk-inline uk-margin">
                <video src={videoInfo.file_url} controls></video>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ShareVideoPage;
