import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { CreatePaymentURL } from "../assest/api/BaseURL";
import { CountryCode } from "../assest/data/Data";

const SubscriptionForm = ({ user, authToken, plan, showToast }) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCardElementValid, setCardElementValid] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    address: "",
    apartment: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
  });

  const cardElementContainerStyle = {
    padding: "10px",
    border: "1px solid #e5e5e5",
    borderRadius: "6px",
  };

  const cardElementStyle = {
    base: {
      fontSize: "14px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  };

  const handleChange = ({ error, complete }) => {
    if (error && !complete) {
      setCardElementValid(false);
      setErrorMessage(error.message);
    } else {
      setCardElementValid(true);
      setErrorMessage("");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Tokenize the card details
    const { error, token } = await stripe.createToken(cardElement, {
      name: formValues.name,
      email: formValues.email,
      address_line1: formValues.address,
      address_line2: formValues.apartment,
      address_city: formValues.city,
      address_state: formValues.state,
      address_country: formValues.country,
      address_zip: formValues.zip_code,
    });

    if (error) {
      console.error(error);
      // Handle error
    } else {
      // Send token and additional data to the server
      console.log("token -------->", token);
      console.log("tokenId -------->", token.id);
      console.log("planId -------->", plan.planDetailsId);

      try {
        const response = await axios({
          method: "post",
          url: CreatePaymentURL,
          data: { token: token.id, planId: plan.planDetailsId },
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`,
          },
        });
        console.log("response -------->", response);
        if (response.status === 200) {
          cardElement.clear();
          setFormValues({
            name: "",
            email: "",
            address: "",
            apartment: "",
            city: "",
            state: "",
            country: "",
            zip_code: "",
          });
          showToast("success", response.data.message);
          setTimeout(() => {
            navigate("/upgrade");
          }, 3000);
        }
      } catch (error) {
        if (error.response.data.error) {
          showToast("error", error.response.data.error);
        } else {
          showToast("error", error.message);
        }
        console.log(error);
      }
    }
    setLoading(false);
  };

  const isFormValid =
    !!stripe &&
    !!elements &&
    formValues.name &&
    formValues.email &&
    formValues.address &&
    formValues.city &&
    formValues.state &&
    formValues.country &&
    formValues.zip_code &&
    isCardElementValid &&
    !errorMessage;

  useEffect(() => {
    if (user.full_name && user.email) {
      setFormValues({
        name: user.full_name,
        email: user.email,
        address: "",
        apartment: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
      });
    }
  }, [user.full_name, user.email]);

  return (
    <>
      <div className="contentdiv">
        <div className="uk-grid" uk-grid="">
          <div className="uk-width-expand@m">
            <h3 className="ukhd">Upgrade to Sceero Premium</h3>
          </div>
          <div className="uk-width-auto@m uk-text-right uk-margin-small-bottom">
            <button
              className="uk-button uk-button-default setting_custombtn secondarybtn"
              onClick={(e) => {
                e.preventDefault();
                navigate("/upgrade");
              }}
            >
              <i className="fa fa-refresh"></i>&nbsp;Cancel
            </button>
          </div>
        </div>
        <div className="uk-card uk-card-default uk-card-body pcard">
          <div className="uk-grid uk-grid-large" uk-grid="">
            <div className="uk-width-expand@m billcss">
              <div>
                <div className="circle">2</div>
                <b>Enter payment details</b>
              </div>
              <form
                className="uk-grid-small uk-grid uk-margin-small-top"
                uk-grid=""
              >
                <div className="uk-width-1-1@s">
                  <div className="uk-margin">
                    <label className="uk-form-label">Card details</label>
                    <div className="uk-form-controls">
                      <div style={cardElementContainerStyle}>
                        <CardElement
                          options={{
                            style: cardElementStyle,
                            hidePostalCode: true,
                          }}
                          onChange={handleChange}
                        />
                      </div>
                      {errorMessage && (
                        <span style={{ color: "red" }}>{errorMessage}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="uk-width-1-2@s">
                  <div className="uk-margin">
                    <label className="uk-form-label">
                      Customer or company name
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        type="text"
                        placeholder="Enter Customer or company name"
                        name="name"
                        value={formValues.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <div className="uk-margin">
                    <label className="uk-form-label">Billing email</label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        name="email"
                        type="email"
                        readOnly={true}
                        placeholder="Enter Billing email"
                        value={formValues.email}
                      />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <div className="uk-margin">
                    <label className="uk-form-label">Street address</label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        name="address"
                        type="text"
                        placeholder="Enter Street address"
                        value={formValues.address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <div className="uk-margin">
                    <label className="uk-form-label">
                      Apt, suite, etc. (optional)
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        name="apartment"
                        type="text"
                        placeholder="Enter Apt, suite, etc. (optional)"
                        value={formValues.apartment}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <div className="uk-margin">
                    <label className="uk-form-label">City </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        type="text"
                        placeholder="City"
                        name="city"
                        value={formValues.city}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <div className="uk-margin">
                    <label className="uk-form-label">State </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        type="text"
                        placeholder="State"
                        name="state"
                        value={formValues.state}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <div className="uk-margin">
                    <label className="uk-form-label">Zip code </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        type="text"
                        placeholder="Zip code"
                        name="zip_code"
                        value={formValues.zip_code}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <div className="uk-margin">
                    <label className="uk-form-label">Country </label>
                    <div className="uk-form-controls">
                      <select
                        className="uk-select"
                        name="country"
                        value={formValues.country}
                        onChange={handleInputChange}
                      >
                        <option value="" label="Select a country code">
                          Select a country
                        </option>
                        {CountryCode.map((country) => {
                          return (
                            <option key={country.code} value={country.name}>
                              {country.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="uk-width-1-1@s">
                  <small className="">
                    Your new subscription will begin immediately. Any previous
                    payments you made will be credited towards your new plan.
                    The amount you owe today is the difference in price between
                    your old plan and your new plan, for the remainder of the
                    billing period. Each month you will be charged at a rate of
                    $10.00/mo per Creator account until you cancel your
                    subscription. You can add new creator accounts to your
                    workspace at any time, and you will be charged for those
                    creators on a pro-rated basis on your next bill.
                  </small>
                </div>
              </form>
              &nbsp;&nbsp;
              <div className="uk-width-1-1@s">
                <button
                  className="uk-button uk-button-default setting_custombtn"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/upgrade-type");
                  }}
                >
                  <i className="fa fa-long-arrow-left"></i>&nbsp; Back
                </button>
              </div>
            </div>
            <div className="uk-width-large@m">
              <h4>Order summary</h4>
              {plan.planDetailsFeatureFlag === "monthly" && (
                <table className="uk-table uk-table-small annuallytbl">
                  <tbody>
                    <tr>
                      <td>${plan.planPerMonthAmount / 100}.00 USD × 1 month</td>
                      <td className="uk-text-right">
                        ${plan.planDetailsAmount / 100}.00 USD
                      </td>
                    </tr>
                    <tr className="totaldiv">
                      <td>
                        <b>Total</b>
                      </td>
                      <td className="uk-text-right">
                        <b>${plan.planDetailsAmount / 100}.00 USD</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              {plan.planDetailsFeatureFlag === "yearly" && (
                <table className="uk-table uk-table-small annuallytbl">
                  <tbody>
                    <tr>
                      <td>${plan.planPerMonthAmount / 100}.00 USD × 12 month</td>
                      <td className="uk-text-right">
                        ${plan.planDetailsAmount / 100}.00 USD
                      </td>
                    </tr>
                    <tr className="totaldiv">
                      <td>
                        <b>Total</b>
                      </td>
                      <td className="uk-text-right">
                        <b>${plan.planDetailsAmount / 100}.00 USD</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              <div className="uk-text-center">
                <button
                  type="submit"
                  className="uk-button uk-button-default payment_custombtn"
                  disabled={!isFormValid || loading}
                  onClick={handleSubmit}
                >
                  {loading ? "Processing..." : "Upgrade subscription"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionForm;
