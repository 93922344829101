// export const BaseURL = "http://localhost:8000";
export const BaseURL = "https://screen.baatcheet.online/screen";
export const LoginURL = `${BaseURL}/api/users/login`;
export const SignupURL = `${BaseURL}/api/users/signup`;
export const ForgotURL = `${BaseURL}/api/users/forgotPassword`;
export const ResetURL = `${BaseURL}/api/users/resetPassword`;
export const LogoutURL = `${BaseURL}/api/users/logout`;
export const GetUserURL = `${BaseURL}/api/users/getProfile`;
export const UpdatePasswordURL = `${BaseURL}/api/users/updatePassword`;
export const UploadProfilePicURL = `${BaseURL}/api/users/uploadPicture`;
export const UpdateProfileURL = `${BaseURL}/api/users/saveProfile`;
export const DeleteAccountURL = `${BaseURL}/api/users/deleteAccount`;
export const UploadVideoURL = `${BaseURL}/api/uploadVideo`;
export const GetRecordedVideosURL = `${BaseURL}/api/listUploadVideo`;
export const GetVideoInfoURL = `${BaseURL}/api/singleVideoDetails`;
export const GetShareVideoInfoURL = `${BaseURL}/api/shareVideoInfo`;
export const UpdateVideoNameURL = `${BaseURL}/api/updateVideoTitle`;
export const DeleteVideoURL = `${BaseURL}/api/deleteUploadVideo`;
export const GetVideoCountURL = `${BaseURL}/api/videoCount`;
export const GetUserStatusURL = `${BaseURL}/api/checkStatusSub`;
export const GetPlanDetailsURL = `${BaseURL}/api/planList`;
export const CreatePaymentURL = `${BaseURL}/api/createCharge`;

//******************************Share Link**************************************/
// export const ShareLinkURL = "http://localhost:3000";
export const ShareLinkURL = "https://screen.baatcheet.online";
//******************************Share Link**************************************/