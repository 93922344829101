import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../styles/video_page.css";
import LinkImg from "../../images/web/blink.png";
import SideBar from "../SideBar";
import NavBar from "../NavBar";
import {
  GetUserURL,
  GetVideoInfoURL,
  UpdateVideoNameURL,
  GetVideoCountURL,
  ShareLinkURL,
} from "../assest/api/BaseURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VideoPage = () => {
  const navigate = useNavigate();
  const { video_id } = useParams();
  const [token, setToken] = useState("");
  const [videoInfo, setVideoInfo] = useState({});
  const [videoName, setVideoName] = useState("");
  const [isLoading, setIsloading] = useState(true);
  const [videoCount, setVideoCount] = useState(0);
  const [user, setUser] = useState({
    full_name: "",
    email: "",
    country_code: null,
    contact_no: null,
    state: null,
    pincode: null,
    address: null,
    profile_pic: null,
  });

  const handleSaveVideoName = async () => {
    if (videoName.length === 0) {
      setVideoName(videoInfo.original_fileName);
      return;
    }

    try {
      const response = await axios({
        method: "put",
        url: UpdateVideoNameURL,
        data: {
          video_id: video_id,
          original_fileName: videoName,
        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setVideoName(videoName);
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
      } else {
        alert(error.message);
      }
      console.log(error);
    }
  };

  const showToast = (type, message) => {
    if (type === "error") {
      toast.error(message);
    }

    if (type === "success") {
      toast.success(message);
    }
  };

  const handleCopy = (id) => {
    const videoUrl = `${ShareLinkURL}/video/${id}`;
    navigator.clipboard
      .writeText(videoUrl)
      .then(() => toast.success("Link Copied Successfully !"))
      .catch((error) => console.error("Failed to copy video URL:", error));
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate(`/share_video/${video_id}`);
    }
  }, [navigate, video_id]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (token) {
      async function getUser() {
        try {
          const response = await axios.get(GetUserURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setUser(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
      getUser();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const getVideoData = async () => {
        try {
          const response = await axios.get(
            GetVideoInfoURL + `?video_id=${video_id}`,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          );

          if (response.status === 200 && response.data.data) {
            setVideoInfo(response.data.data);
            setVideoName(response.data.data.original_fileName);
            setIsloading(false);
          } else {
            navigate("*");
          }
        } catch (error) {
          console.log(error);
        }
      };
      getVideoData();
    }
  }, [token, video_id, navigate]);

  useEffect(() => {
    if (token) {
      const getVideosCount = async () => {
        try {
          const response = await axios.get(GetVideoCountURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setVideoCount(response.data.data.count);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getVideosCount();
    }
  }, [token]);

  return (
    <>
      <div className="background_img">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-collapse" uk-grid="">
              <SideBar active="dashboard" token={token} showToast={showToast} />
              <div className="uk-width-expand">
                <NavBar
                  user={user}
                  token={token}
                  showToast={showToast}
                  videoCount={videoCount}
                />

                {isLoading ? (
                  <div className="content">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div uk-spinner="ratio: 3"></div>
                    </div>
                  </div>
                ) : (
                  <div className="content">
                    <ul uk-tab="" className="custom_tab">
                      <li>
                        <span>Video</span>
                      </li>
                    </ul>

                    {videoInfo && (
                      <ul className="fswitcher uk-margin">
                        <li>
                          <ul className="sswitcher uk-margin">
                            <li>
                              <div>
                                <div className="uk-width-1-1 pl-pr3rem mb1rem">
                                  <div uk-grid="">
                                    <div className="uk-width-expand">
                                      {user.id === videoInfo.id ? (
                                        <input
                                          className="uk-input inputcss"
                                          type="text"
                                          placeholder="Enter Name"
                                          value={videoName}
                                          onChange={(e) =>
                                            setVideoName(e.target.value)
                                          }
                                          onBlur={handleSaveVideoName}
                                        />
                                      ) : (
                                        <input
                                          className="uk-input inputcss"
                                          type="text"
                                          placeholder="Lorem Ipsum"
                                          readOnly={true}
                                          value={videoName}
                                        />
                                      )}
                                    </div>
                                    <div>
                                      <img
                                        src={LinkImg}
                                        alt="LinkImg"
                                        className="mr20"
                                        style={{
                                          cursor: "pointer",
                                          marginTop: "10px",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleCopy(videoInfo.video_id);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-1 uk-text-center">
                                  <div className="uk-inline uk-margin">
                                    <video
                                      src={videoInfo.file_url}
                                      controls
                                    ></video>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default VideoPage;
