import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { LoginURL } from "../assest/api/BaseURL";
import Logo from "../../images/web/logo2.png";
import "../styles/style.css";

const Login = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [visible, setVisible] = useState(false);

  const handleInput = (event) => {
    setUser((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (user.email === "") {
      setError("Email should not be empty");
    } else if (!email_pattern.test(user.email)) {
      setError("Please Enter valid email");
    } else if (user.password === "") {
      setError("Password should not be empty");
    } else {
      try {
        const response = await axios.post(LoginURL, {
          email: user.email,
          password: user.password,
        });

        if (response.status === 200) {
          localStorage.setItem("token", response.data.data.token);
          navigate("/dashboard");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setError("Invalid Credentials !");
          setUser({ email: "", password: "" });
        } else {
          setError(error.message);
          setUser({ email: "", password: "" });
        }
        console.log(error);
      }
    }
  };

  const handlePasswordVisible = (e) => {
    e.preventDefault();
    setVisible(!visible);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <>
      <div className="modal_container videoscreen">
        <div className="uk-card uk-card-default uk-card-body logincardcss">
          <div className="uk-text-center">
            <img src={Logo} alt="LOGO" className="login_logo" />
          </div>

          <form className="uk-form-stacked loginform">
            <h3>Login</h3>

            {error && (
              <div className="uk-alert-danger uk-alert" uk-alert="">
                {error}
              </div>
            )}

            <div className="uk-margin">
              <label className="uk-form-label">Email</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  type="email"
                  placeholder=""
                  name="email"
                  value={user.email}
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="uk-margin">
              <label className="uk-form-label"> Password</label>
              <div className="uk-grid-small" uk-grid="">
                <div className="uk-width-expand@m">
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type={visible ? "text" : "password"}
                      placeholder=""
                      name="password"
                      value={user.password}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="uk-width-auto@m">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handlePasswordVisible}
                  >
                    {visible ? (
                      <i className="fa fa-eye-slash logineye"></i>
                    ) : (
                      <i className="fa fa-eye logineye"></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="uk-text-center uk-width-1-1@s mrtop30">
              <button
                className="uk-button uk-button-default loginbtn"
                onClick={handleSubmit}
              >
                Login
              </button>
              <Link to="/forgot_password">
                <p className="forgot_text">Forgot Password?</p>
              </Link>
            </div>
            <Link to="/signup">
              <h4>Create An Account</h4>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
