import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import RecordRTC from "recordrtc";
import axios from "axios";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../images/logo.png";
import VideoIcon from "../../images/video_icon.png";
import MicrophoneImg from "../../images/microphone.png";
import SpeedometerImg from "../../images/speedometer.png";
import MonitorImg from "../../images/monitor.png";
import "../styles/modal.css";
import "react-toastify/dist/ReactToastify.css";
import {
  UploadVideoURL,
  GetUserStatusURL,
  ShareLinkURL,
} from "../assest/api/BaseURL";
import BeepSound from "../assest/sound/beep.mp3";
import CountdownTimer from "../CountdownTimer";
import Loader from "../Loader";

const ExtensionPage = () => {
  const videoQualityOptions = [
    { label: "SD (480p)", width: 854, height: 480 },
    { label: "Auto (720p)", width: 1280, height: 720 },
    { label: "HD (1080p)", width: 1920, height: 1080 },
    { label: "2K (1440p)", width: 2560, height: 1440 },
  ];
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isMicrophoneOn, setMicrophoneOn] = useState(true);
  const [isRecordingType, setRecordingType] = useState(true);
  const [connectionType, setConnectionType] = useState("checking..");
  const [selectedVideoQuality, setSelectedVideoQuality] = useState(
    videoQualityOptions[1]
  );
  const [timer, setTimer] = useState(10 * 60); // 10 minutes in seconds
  const [timerInMillisec, setTimerInMillisec] = useState(10 * 60 * 1000);
  const [ctimer, setCTimer] = useState(3);
  const [isCountdownStarted, setIsCountdownStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [browser, setBrowser] = useState("");
  const [mimeType, setMimeType] = useState("video/mp4");
  const [extensionType, setExtensionType] = useState(".mp4");
  const [userStatus, setUserStatus] = useState("");
  const timerIntervalRef = useRef(null);
  const recorderRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const videoRef = useRef(null);

  const startWebcam = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });
  };

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    if (name === "microphone") {
      setMicrophoneOn(value === "on");
    } else if (name === "recordingType") {
      setRecordingType(value === "Screen Only");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const getConnectionCategory = (speed) => {
    switch (true) {
      case speed < 2:
        return "slow";
      case speed >= 2 && speed < 5:
        return "medium";
      case speed >= 5:
        return "fast";
      default:
        return "Unknown";
    }
  };

  const startCountdownTimer = (duration, onTick, onComplete) => {
    let countdown = duration;
    setIsCountdownStarted(true);

    const timer = setInterval(() => {
      if (countdown > 0) {
        onTick(countdown);
        countdown--;
      } else {
        clearInterval(timer);
        setIsCountdownStarted(false);
        onComplete();
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  };

  const handleCopy = (id) => {
    const videoUrl = `${ShareLinkURL}/video/${id}`;
    navigator.clipboard
      .writeText(videoUrl)
      .then(() => console.log("Link Copied Successfully !"))
      .catch((error) => console.error("Failed to copy video URL:", error));
  };

  const startRecording = () => {
    const videoConstraints = {
      video: {
        width: { ideal: selectedVideoQuality.width },
        height: { ideal: selectedVideoQuality.height },
      },
      audio: true,
    };

    const videoConstraintsForCam = {
      width: { ideal: selectedVideoQuality.width },
      height: { ideal: selectedVideoQuality.height },
      frameRate: { ideal: 30 },
    };

    const options = {
      type: "video",
      mimeType: mimeType,
    };

    if (isRecordingType) {
      navigator.mediaDevices
        .getDisplayMedia(videoConstraints)
        .then((screenStream) => {
          if (isMicrophoneOn) {
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then((audioStream) => {
                const mixedStream = new MediaStream();
                const audioTrack = audioStream.getAudioTracks()[0];
                mixedStream.addTrack(audioTrack);
                const videoTrack = screenStream.getVideoTracks()[0];
                mixedStream.addTrack(videoTrack);

                mediaStreamRef.current = mixedStream;

                const recorder = new RecordRTC(mixedStream, options);
                recorderRef.current = recorder;

                const onComplete = () => {
                  const beepSound = new Audio(BeepSound);
                  beepSound.play();
                  recorder.startRecording();
                  setIsRecording(true);
                  // Start the timer
                  timerIntervalRef.current = setInterval(() => {
                    setTimer((prevTimer) => prevTimer - 1);
                  }, 1000);

                  // Stop the recording after 10 minutes
                  setTimeout(stopRecording, timerInMillisec);
                };

                const onTick = (countdown) => {
                  setCTimer(countdown);
                };

                startCountdownTimer(3, onTick, onComplete);
              })
              .catch((audioError) => {
                console.error("Error accessing audio:", audioError);
                toast.error(`Error accessing audio: ${audioError}`);
              });
          } else {
            mediaStreamRef.current = screenStream;

            const recorder = new RecordRTC(screenStream, options);
            recorderRef.current = recorder;

            const onComplete = () => {
              const beepSound = new Audio(BeepSound);
              beepSound.play();
              recorder.startRecording();
              setIsRecording(true);
              // Start the timer
              timerIntervalRef.current = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
              }, 1000);

              // Stop the recording after 10 minutes
              setTimeout(stopRecording, timerInMillisec);
            };

            const onTick = (countdown) => {
              setCTimer(countdown);
            };

            startCountdownTimer(3, onTick, onComplete);
          }
        })
        .catch((screenError) => {
          console.error("Error accessing screen:", screenError);
          toast.error(`Error accessing screen: ${screenError}`);
        });
    } else {
      if (isMicrophoneOn) {
        navigator.mediaDevices
          .getUserMedia({ video: videoConstraintsForCam, audio: true })
          .then((cameraStream) => {
            mediaStreamRef.current = { cameraStream };

            const cameraRecorder = new RecordRTC(cameraStream, options);

            recorderRef.current = {
              cameraRecorder,
            };

            const onComplete = () => {
              const beepSound = new Audio(BeepSound);
              beepSound.play();
              cameraRecorder.startRecording();
              setIsRecording(true);
              // Start the timer
              timerIntervalRef.current = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
              }, 1000);

              // Stop the recording after 10 minutes
              setTimeout(stopRecording, timerInMillisec);
            };

            const onTick = (countdown) => {
              setCTimer(countdown);
            };

            startCountdownTimer(3, onTick, onComplete);
          })
          .catch((cameraError) => {
            console.error("Error accessing camera:", cameraError);
            toast.error(`Error accessing camera: ${cameraError}`);
          });
      } else {
        navigator.mediaDevices
          .getUserMedia({ video: videoConstraintsForCam, audio: false })
          .then((cameraStream) => {
            mediaStreamRef.current = { cameraStream };

            const cameraRecorder = new RecordRTC(cameraStream, options);

            recorderRef.current = {
              cameraRecorder,
            };

            const onComplete = () => {
              const beepSound = new Audio(BeepSound);
              beepSound.play();
              cameraRecorder.startRecording();
              setIsRecording(true);
              // Start the timer
              timerIntervalRef.current = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
              }, 1000);

              // Stop the recording after 10 minutes
              setTimeout(stopRecording, timerInMillisec);
            };

            const onTick = (countdown) => {
              setCTimer(countdown);
            };

            startCountdownTimer(3, onTick, onComplete);
          })
          .catch((cameraError) => {
            console.error("Error accessing camera:", cameraError);
            toast.error(`Error accessing camera: ${cameraError}`);
          });
      }
    }
  };

  const stopRecording = async () => {
    const recorder = recorderRef.current;
    const mediaStream = mediaStreamRef.current;
    const beepSound = new Audio(BeepSound);
    beepSound.play();

    if (!isRecordingType) {
      recorder.cameraRecorder.stopRecording(async () => {
        const cameraBlob = recorder.cameraRecorder.getBlob();

        mediaStream.cameraStream.getTracks().forEach((track) => track.stop());

        if (videoRef.current) {
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          }
          videoRef.current.srcObject = null;
        }

        const cameraData = new FormData();
        // cameraData.append("video", cameraBlob, "camera-Record");
        cameraData.append("video", cameraBlob, `camera-Record${extensionType}`);

        // Clear the timer interval
        clearInterval(timerIntervalRef.current);
        setIsRecording(false);
        setIsLoading(true);

        try {
          const response = await axios({
            method: "post",
            url: UploadVideoURL,
            data: cameraData,
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
            handleCopy(response.data.data.video_id);
            navigate(`/video/${response.data.data.video_id}`);
            setIsLoading(false);
            // handleModal(false);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert(error.message);
          }
          console.log(error);
        }
      });
    } else {
      recorder.stopRecording(async () => {
        const blob = recorder.getBlob();
        mediaStream.getTracks().forEach((track) => track.stop());

        const data = new FormData();
        // data.append("video", blob, "screen-Record.webm");
        data.append("video", blob, `screen-Record${extensionType}`);

        // Clear the timer interval
        clearInterval(timerIntervalRef.current);
        setIsRecording(false);
        setIsLoading(true);

        try {
          const response = await axios({
            method: "post",
            url: UploadVideoURL,
            data: data,
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
            handleCopy(response.data.data.video_id);
            navigate(`/video/${response.data.data.video_id}`);
            setIsLoading(false);
            // handleModal(false);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert(error.message);
          }
          console.log(error);
        }
      });
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (isRecording && !isRecordingType) {
      startWebcam();
    }
  }, [isRecording, isRecordingType]);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // Check if the user is using Chrome
    if (/chrome/i.test(userAgent)) {
      //  setBrowser("Chrome");
      setMimeType("video/webm;codecs=h264");
      setExtensionType(".webm");
    }

    // Check if the user is using Firefox
    else if (/firefox/i.test(userAgent)) {
      //  setBrowser("Firefox");
      setMimeType("video/webm");
      setExtensionType(".webm");
    }

    // Check if the user is using Safari
    else if (/safari/i.test(userAgent)) {
      //  setBrowser("Safari");
      setMimeType("video/mp4;codecs=avc1");
      setExtensionType(".mp4");
    }

    // Check if the user is using Internet Explorer
    else if (/msie|trident/i.test(userAgent)) {
      //  setBrowser("Internet Explorer");
      setMimeType("video/mp4");
      setExtensionType(".mp4");
    }

    // Check if the user is using Microsoft Edge
    else if (/edg/i.test(userAgent)) {
      //  setBrowser("Microsoft Edge");
      setMimeType("video/mp4");
      setExtensionType(".mp4");
    }
  }, []);

  useEffect(() => {
    if (token) {
      const getUserStatus = async () => {
        try {
          const response = await axios.get(GetUserStatusURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setUserStatus(response.data.data.status);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getUserStatus();
    }
  }, [token]);

  useEffect(() => {
    if (userStatus === "active") {
      setTimer(30 * 60);
      setTimerInMillisec(30 * 60 * 1000);
    }
  }, [userStatus]);

  return (
    <>
      <div
        className="uk-open"
        style={{ display: "block" }}
        uk-modal="bg-close:false;"
      >
        {/* <div> */}
        <div className="modal_container videoscreen">
          <div className="uk-card uk-card-default uk-card-body cardcss">
            <div className="uk-width-1-1 topbar">
              <div className="" uk-grid="">
                <div className="uk-width-expand">
                  <img src={Logo} alt="LogoImg" className="wlogo" />{" "}
                  <span className="logotxt">Sceero</span>
                </div>
              </div>
            </div>

            {isRecording ? (
              <div>
                <ul className="uk-subnav uk-subnav-pill tabcss" uk-switcher="">
                  <li id="firsttab">
                    <span>
                      <img src={VideoIcon} alt="VideoIcon" className="" />
                    </span>
                  </li>
                </ul>

                <ul className="uk-switcher uk-margin screencol">
                  <li>
                    <ul className="uk-flex-center screentab" uk-tab="">
                      <li>
                        <span>
                          Recording Will Stop In : {formatTime(timer)}{" "}
                        </span>
                      </li>
                    </ul>

                    {!isRecordingType && (
                      <video
                        ref={videoRef}
                        autoPlay
                        muted
                        style={{ height: "auto", padding: "10px" }}
                      />
                    )}

                    <ul className="uk-switcher uk-margin ">
                      <li className="screentab_value">
                        <form className="formcss">
                          <button
                            className="uk-button uk-button-default start strleft"
                            onClick={(e) => {
                              e.preventDefault();
                              stopRecording();
                            }}
                          >
                            {" "}
                            Stop Recording
                          </button>
                        </form>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            ) : (
              <div>
                <span className="uk-margin">
                  <li className="screentab_value" style={{listStyle:"none"}}>
                    <form className="formcss">
                      <div className="uk-margin">
                        <label className="uk-form-label">Recording Type</label>
                        <div className="uk-form-controls">
                          <div className="uk-inline">
                            <span className="uk-form-icon">
                              <img
                                src={MonitorImg}
                                alt="MonitorImg"
                                style={{ width: "18px" }}
                              />
                            </span>
                            <select
                              className="uk-select"
                              name="recordingType"
                              value={
                                isRecordingType ? "Screen Only" : "Cam Only"
                              }
                              onChange={handleOptionChange}
                            >
                              <option value="Screen Only">Screen Only</option>
                              <option value="Cam Only">Cam Only</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label">Microphone</label>
                        <div className="uk-form-controls">
                          <div className="uk-inline">
                            <span className="uk-form-icon">
                              <img
                                src={MicrophoneImg}
                                alt="MicrophoneImg"
                                className=""
                                style={{ width: "20px" }}
                              />
                            </span>
                            <select
                              className="uk-select"
                              name="microphone"
                              value={isMicrophoneOn ? "on" : "off"}
                              onChange={handleOptionChange}
                            >
                              <option value="on">Microphone On</option>
                              <option value="off">Microphone Off</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="uk-width-1-1 mrtop210">
                        <div className="uk-grid-small uk-grid" uk-grid="">
                          <div className="uk-width-auto@m valign uk-first-column">
                            <span className="speedotxt">
                              <img
                                src={SpeedometerImg}
                                alt="SpeedometerImg"
                                className="speedometer"
                              />{" "}
                              Internet speed is {connectionType}.
                            </span>
                          </div>
                          <div className="uk-width-expand@m uk-text-right">
                            <select
                              className="uk-select screensize"
                              value={selectedVideoQuality.label}
                              onChange={(e) => {
                                const selectedOption = videoQualityOptions.find(
                                  (option) => option.label === e.target.value
                                );
                                setSelectedVideoQuality(selectedOption);
                              }}
                            >
                              {videoQualityOptions.map((option) => (
                                <option key={option.label} value={option.label}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <button
                        className="uk-button uk-button-default start strleft"
                        onClick={(e) => {
                          e.preventDefault();
                          startRecording();
                        }}
                      >
                        {" "}
                        Start Recording
                      </button>
                    </form>
                  </li>
                </span>
              </div>
            )}

            {isCountdownStarted && <CountdownTimer countdown={ctimer} />}
          </div>
        </div>
      </div>

      {isLoading && <Loader />}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <ReactInternetSpeedMeter
        txtSubHeading={"Internet is too slow "}
        outputType=""
        customClassName={null}
        pingInterval={3000} // milliseconds
        thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
        threshold={8}
        imageUrl="https://www.sammobile.com/wp-content/uploads/2019/03/keyguard_default_wallpaper_silver.png"
        downloadSize="2550420" //bytes
        // callbackFunctionOnNetworkDown={(speed) =>
        //   setConnectionType(getConnectionCategory(speed))
        // }
        callbackFunctionOnNetworkTest={(speed) =>
          setConnectionType(getConnectionCategory(speed))
        }
      />
    </>
  );
};

export default ExtensionPage;
