import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/home.css";
import {
  GetUserURL,
  GetVideoCountURL,
  GetPlanDetailsURL,
} from "../assest/api/BaseURL";
import SideBar from "../SideBar";
import NavBar from "../NavBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpgradeTypePage = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [monthlyPlan, setMonthlyPlan] = useState({});
  const [yearlyPlan, setYearlyPlan] = useState({});
  const [videoCount, setVideoCount] = useState(0);
  const [user, setUser] = useState({
    full_name: "",
    email: "",
    country_code: null,
    contact_no: null,
    state: null,
    pincode: null,
    address: null,
    profile_pic: null,
  });

  const showToast = (type, message) => {
    if (type === "error") {
      toast.error(message);
    }

    if (type === "success") {
      toast.success(message);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (token) {
      async function getUser() {
        try {
          const response = await axios.get(GetUserURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setUser(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
      getUser();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      async function getPlanDetails() {
        try {
          const response = await axios.get(GetPlanDetailsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            const dataM = response.data.data.filter(
              (item) => item.planDetailsFeatureFlag === "monthly"
            );
            setMonthlyPlan(dataM[0]);

            const dataY = response.data.data.filter(
              (item) => item.planDetailsFeatureFlag === "yearly"
            );
            setYearlyPlan(dataY[0]);
          }
        } catch (error) {
          console.log(error);
        }
      }
      getPlanDetails();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const getVideosCount = async () => {
        try {
          const response = await axios.get(GetVideoCountURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setVideoCount(response.data.data.count);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getVideosCount();
    }
  }, [token]);

  return (
    <>
      <div className="background_img">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-collapse" uk-grid="">
              <SideBar active="upgrade" token={token} showToast={showToast} />
              <div className="uk-width-expand">
                <NavBar
                  user={user}
                  token={token}
                  showToast={showToast}
                  videoCount={videoCount}
                />

                <div className="content">
                  <div className="contentdiv">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand@m">
                        <h3 className="ukhd">Upgrade to Sceero Premium</h3>
                      </div>
                      <div className="uk-width-auto@m uk-text-right uk-margin-small-bottom">
                        <button
                          className="uk-button uk-button-default setting_custombtn secondarybtn"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/upgrade");
                          }}
                        >
                          <i className="fa fa-refresh"></i>&nbsp;Cancel
                        </button>
                      </div>
                    </div>
                    <div className="uk-card uk-card-default uk-card-body pcard">
                      <div className="uk-grid uk-grid-large" uk-grid="">
                        <div className="uk-width-expand@m billcss">
                          <div>
                            <div className="circle">1</div>
                            <b>Billing cycle</b>
                          </div>

                          <div className="billcrd">
                            <ul
                              className="uk-tab-left"
                              uk-tab="connect: #component-tab-left; animation: uk-animation-fade"
                            >
                              <li>
                                <div className="" uk-grid="">
                                  <div className="uk-width-expand@s">
                                    <b>Monthly</b>
                                    <br />
                                    <b>
                                      $
                                      {monthlyPlan &&
                                        monthlyPlan.planPerMonthAmount / 100}
                                      .00
                                    </b>{" "}
                                    per month
                                  </div>
                                  <div className="uk-width-auto@s valign">
                                    <i className="fa fa-check-square-o"></i>
                                  </div>
                                </div>
                              </li>
                              &nbsp;&nbsp;
                              <li>
                                <div className="" uk-grid="">
                                  <div className="uk-width-expand@s">
                                    <b>Anually</b>{" "}
                                    {/* <span className="uk-label bluelbl">
                                      20% OFF
                                    </span> */}
                                    <br />
                                    <b>
                                      $
                                      {yearlyPlan &&
                                        yearlyPlan.planPerMonthAmount / 100}
                                      .00
                                    </b>{" "}
                                    per month
                                  </div>
                                  <div className="uk-width-auto@s valign">
                                    <i className="fa fa-check-square-o"></i>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {/* <ul uk-accordion="" className="custacc">
                            <li className="uk-open">
                              <a className="uk-accordion-title" href="#">
                                Have a promo code?
                              </a>
                              <div className="uk-accordion-content">
                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-medium@m">
                                    <input
                                      className="uk-input"
                                      name=""
                                      type="text"
                                      placeholder="Enter Code"
                                      value=""
                                    />
                                  </div>
                                  <div className="uk-width-auto@m">
                                    <button
                                      type="submit"
                                      className="uk-button uk-button-default setting_custombtn"
                                      name=""
                                    >
                                      Apply Code
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul> */}
                        </div>
                        <div className="uk-width-large@m">
                          <h4>Order summary</h4>
                          <ul id="component-tab-left" className="uk-switcher">
                            <li>
                              <table className="uk-table uk-table-small annuallytbl">
                                <tbody>
                                  <tr>
                                    <td>
                                      $
                                      {monthlyPlan &&
                                        monthlyPlan.planPerMonthAmount / 100}
                                      .00 USD × 1 month
                                    </td>
                                    <td className="uk-text-right">
                                      $
                                      {monthlyPlan &&
                                        monthlyPlan.planDetailsAmount / 100}
                                      .00 USD
                                    </td>
                                  </tr>
                                  <tr className="totaldiv">
                                    <td>
                                      <b>Total</b>
                                    </td>
                                    <td className="uk-text-right">
                                      <b>
                                        $
                                        {monthlyPlan &&
                                          monthlyPlan.planDetailsAmount / 100}
                                        .00 USD
                                      </b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="uk-text-center uk-margin-small-top">
                                <button
                                  type="submit"
                                  className="uk-button uk-button-default setting_custombtn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/upgrade-type/monthly");
                                  }}
                                >
                                  Continue to payment details
                                </button>
                              </div>
                            </li>

                            <li>
                              <table className="uk-table uk-table-small annuallytbl">
                                <tbody>
                                  <tr>
                                    <td>
                                      $
                                      {yearlyPlan &&
                                        yearlyPlan.planPerMonthAmount / 100}
                                      .00 USD × 12 months
                                    </td>
                                    <td className="uk-text-right">
                                      $
                                      {yearlyPlan &&
                                        yearlyPlan.planDetailsAmount / 100}
                                      .00 USD
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>20% off paid annually</td>
                                    <td className="uk-text-right">
                                      -$24.00 USD
                                    </td>
                                  </tr> */}
                                  <tr className="totaldiv">
                                    <td>
                                      <b>Total</b>
                                    </td>
                                    <td className="uk-text-right">
                                      <b>
                                        $
                                        {yearlyPlan &&
                                          yearlyPlan.planDetailsAmount / 100}
                                        .00 USD
                                      </b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="uk-text-center uk-margin-small-top">
                                <button
                                  type="submit"
                                  className="uk-button uk-button-default setting_custombtn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/upgrade-type/yearly");
                                  }}
                                >
                                  Continue to payment details
                                </button>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default UpgradeTypePage;
