import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../styles/home.css";
import {
  GetPlanDetailsURL,
  GetUserURL,
  GetVideoCountURL,
} from "../assest/api/BaseURL";
import SideBar from "../SideBar";
import NavBar from "../NavBar";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_PUBLISHED_KEY } from "../assest/data/Data";
import SubscriptionForm from "./SubscriptionForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const stripePromise = loadStripe(STRIPE_PUBLISHED_KEY);

const PaymentPage = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const [token, setToken] = useState("");
  const [plan, setPlan] = useState({});
  const [videoCount, setVideoCount] = useState(0);
  const [user, setUser] = useState({
    full_name: "",
    email: "",
    country_code: null,
    contact_no: null,
    state: null,
    pincode: null,
    address: null,
    profile_pic: null,
  });

  const showToast = (type, message) => {
    if (type === "error") {
      toast.error(message);
    }

    if (type === "success") {
      toast.success(message);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (type) {
      if (type !== "monthly" && type !== "yearly") {
        navigate("*");
      }
    }
  }, [navigate, type]);

  useEffect(() => {
    if (token) {
      async function getUser() {
        try {
          const response = await axios.get(GetUserURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setUser(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
      getUser();
    }
  }, [token]);

  useEffect(() => {
    if (token && type) {
      async function getPlanDetails() {
        try {
          const response = await axios.get(GetPlanDetailsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            const data = response.data.data.filter(
              (item) => item.planDetailsFeatureFlag === type
            );
            setPlan(data[0]);
          }
        } catch (error) {
          console.log(error);
        }
      }
      getPlanDetails();
    }
  }, [token, type]);

  useEffect(() => {
    if (token) {
      const getVideosCount = async () => {
        try {
          const response = await axios.get(GetVideoCountURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setVideoCount(response.data.data.count);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getVideosCount();
    }
  }, [token]);

  return (
    <>
      <div className="background_img">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-collapse" uk-grid="">
              <SideBar active="upgrade" token={token} showToast={showToast} />
              <div className="uk-width-expand">
                <NavBar
                  user={user}
                  token={token}
                  showToast={showToast}
                  videoCount={videoCount}
                />

                <div className="content">
                  <Elements stripe={stripePromise}>
                    <SubscriptionForm
                      user={user}
                      authToken={token}
                      plan={plan}
                      showToast={showToast}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default PaymentPage;
