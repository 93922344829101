import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { ResetURL } from "../assest/api/BaseURL";
import Logo from "../../images/web/logo2.png";
import "../styles/style.css";

const ResetPage = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  // const [visiblePassword, setVisiblePassword] = useState(false);
  // const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const [success, setSuccess] = useState("");

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setError("");
    setSuccess("");
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setError("");
    setSuccess("");
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (password === "") {
      setError("Password should not be empty");
    } else if (password.length < 4) {
      setError("Password must be have minimum 4 characters");
    } else if (confirmPassword === "") {
      setError("Confirm Password should not be empty");
    } else if (password !== confirmPassword) {
      setError("Password and Confirm Password must be same");
    } else {
      try {
        const response = await axios.post(ResetURL, {
          token: token,
          password: password,
          confirmPassword: confirmPassword,
        });

        if (response.status === 200) {
          setSuccess("Password Updated Successfully !");
          setPassword("");
          setConfirmPassword("");
        }
      } catch (error) {
        if (error.response) {
          setError(error.response.data.message);
          setPassword("");
          setConfirmPassword("");
        } else {
          setError(error.message);
          setPassword("");
          setConfirmPassword("");
        }
        console.log(error);
      }
    }
  };

  // const handlePasswordVisible = (e) => {
  //   e.preventDefault();
  //   setVisiblePassword(!visiblePassword);
  // };

  // const handleConfirmPasswordVisible = (e) => {
  //   e.preventDefault();
  //   setVisibleConfirmPassword(!visibleConfirmPassword);
  // };

  return (
    <>
      <div className="modal_container videoscreen">
        <div className="uk-card uk-card-default uk-card-body logincardcss">
          <div className="uk-text-center">
            <img src={Logo} alt="LOGO" className="login_logo" />
          </div>

          <form className="uk-form-stacked loginform">
            <h3>Reset Password</h3>

            {error && (
              <div className="uk-alert-danger uk-alert" uk-alert="">
                {error}
              </div>
            )}
            {success && (
              <div className="uk-alert-success uk-alert" uk-alert="">
                {success}
              </div>
            )}

            <div className="uk-margin">
              <label className="uk-form-label"> Create Password</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  id="form-stacked-text"
                  type="password"
                  placeholder=""
                  name="password"
                  value={password}
                  onChange={handlePassword}
                />
              </div>
            </div>

            <div className="uk-margin">
              <label className="uk-form-label">Confirm Password</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  type="password"
                  placeholder=""
                  name="confirm_password"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                />
              </div>
            </div>
            <div className="uk-text-center uk-width-1-1@s mrtop30">
              <button
                className="uk-button uk-button-default loginbtn"
                onClick={handleResetPassword}
              >
                Submit
              </button>
            </div>
            <Link to="/">
              <h4>Login</h4>
            </Link>
          </form>
        </div>
      </div>

      {/* <div className="background">
        <div className="container">
          <div className="uk-width-1-1" style={resetFormStyle}>
            <div className="formcss">
              <div className="uk-width-1-1 headerddiv">
                <div className="uk-grid">
                  <div className="uk-width-auto"></div>
                  <div className="uk-width-expand uk-text-right">
                    <Link to="/">
                      <span className="uk-button custombtn">Login Account</span>
                    </Link>
                  </div>
                </div>

                <img src={loginImg} className="logingif" alt="LoginImg" />
                <p className="smalltxt">
                  Just enter your password below and reset your password!
                </p>
              </div>
              <h3 className="uk-text-center signinhd">Reset Password</h3>
              <span className="signinsubhd">Welcome Back</span>

              <form className="uk-form-stacked" method="post" action="">
                <div className="inputdiv">
                  {error && (
                    <div className="uk-alert-danger uk-alert" uk-alert="">
                      {error}
                    </div>
                  )}
                  {success && (
                    <div className="uk-alert-success uk-alert" uk-alert="">
                      {success}
                    </div>
                  )}

                  <div className="uk-margin">
                    <div className="uk-grid-small" uk-grid="">
                      <div className="uk-width-expand@m">
                        <div className="uk-inline">
                          <i className="uk-form-icon fa fa-lock"></i>
                          <input
                            className="uk-input"
                            type={visiblePassword ? "text" : "password"}
                            placeholder="Enter your password"
                            name="password"
                            value={password}
                            onChange={handlePassword}
                          />
                        </div>
                      </div>

                      <div className="uk-width-auto@m">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handlePasswordVisible}
                        >
                          {visiblePassword ? (
                            <i className="fa fa-eye-slash logineye"></i>
                          ) : (
                            <i className="fa fa-eye logineye"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="uk-margin">
                    <div className="uk-grid-small" uk-grid="">
                      <div className="uk-width-expand@m">
                        <div className="uk-inline">
                          <i className="uk-form-icon fa fa-lock"></i>
                          <input
                            className="uk-input"
                            type={visibleConfirmPassword ? "text" : "password"}
                            placeholder="Enter your confirm password"
                            name="confirm_password"
                            value={confirmPassword}
                            onChange={handleConfirmPassword}
                          />
                        </div>
                      </div>
                      <div className="uk-width-auto@m">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleConfirmPasswordVisible}
                        >
                          {visibleConfirmPassword ? (
                            <i className="fa fa-eye-slash logineye"></i>
                          ) : (
                            <i className="fa fa-eye logineye"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="uk-text-center uk-width-1-1@s">
                    <button
                      className="uk-button uk-button-default gbtn uk-margin-remove-top"
                      name="forgot_pass"
                      onClick={handleResetPassword}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ResetPage;
