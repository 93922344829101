import React from "react";
import "./styles/CountdownTimer.css";

const CountdownTimer = ({ countdown }) => {
  return (
    <>
      <div
        className="uk-open"
        style={{ display: "block" }}
        uk-modal="bg-close:false;"
      >
        <div className="countdown-timer-container">
          <div className="countdown-timer">
            <div className="countdown-timer__text">{countdown}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountdownTimer;
