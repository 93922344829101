import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { SignupURL } from "../assest/api/BaseURL";
import Logo from "../../images/web/logo2.png";
import "../styles/style.css";
import Loader from "../Loader";

const Signup = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

  const handleInput = (event) => {
    setUser((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (user.name === "") {
      setError("Name should not be empty");
    } else if (user.email === "") {
      setError("Email should not be empty");
    } else if (!email_pattern.test(user.email)) {
      setError("Please Enter valid email");
    } else if (user.password === "") {
      setError("Password should not be empty");
    } else if (user.password.length < 4) {
      setError("Password must be have minimum 4 characters");
    } else if (user.confirm_password === "") {
      setError("Confirm Password should not be empty");
    } else if (user.password !== user.confirm_password) {
      setError("Password and Confirm Password must be same");
    } else {
      setIsLoading(true);
      try {
        const response = await axios.post(SignupURL, {
          full_name: user.name,
          email: user.email,
          password: user.password,
          confirmPassword: user.confirm_password,
        });

        if (response.status === 201) {
          localStorage.setItem("token", response.data.data.token);
          navigate("/dashboard");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setError("User Already Exists!");
          setUser({ name: "", email: "", password: "", confirm_password: "" });
        } else {
          setError(error.message);
          setUser({ name: "", email: "", password: "", confirm_password: "" });
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const handlePasswordVisible = (e) => {
    e.preventDefault();
    setVisiblePassword(!visiblePassword);
  };

  const handleConfirmPasswordVisible = (e) => {
    e.preventDefault();
    setVisibleConfirmPassword(!visibleConfirmPassword);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <>
      <div className="modal_container videoscreen">
        <div className="uk-card uk-card-default uk-card-body logincardcss">
          <div className="uk-text-center">
            <img src={Logo} alt="LOGO" className="login_logo" />
          </div>

          <form className="uk-form-stacked loginform">
            <h3>Create New Account</h3>

            {error && (
              <div className="uk-alert-danger uk-alert" uk-alert="">
                {error}
              </div>
            )}

            <div className="uk-margin">
              <label className="uk-form-label">Name</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  type="text"
                  placeholder=""
                  name="name"
                  value={user.name}
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="uk-margin">
              <label className="uk-form-label">Email</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  type="email"
                  placeholder=""
                  name="email"
                  value={user.email}
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="uk-margin">
              <label className="uk-form-label"> Create Password</label>
              <div className="uk-grid-small" uk-grid="">
                <div className="uk-width-expand@m">
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type={visiblePassword ? "text" : "password"}
                      placeholder=""
                      name="password"
                      value={user.password}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="uk-width-auto@m">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handlePasswordVisible}
                  >
                    {visiblePassword ? (
                      <i className="fa fa-eye-slash logineye"></i>
                    ) : (
                      <i className="fa fa-eye logineye"></i>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="uk-margin">
              <label className="uk-form-label">Confirm Password</label>
              <div className="uk-grid-small" uk-grid="">
                <div className="uk-width-expand@m">
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type={visibleConfirmPassword ? "text" : "password"}
                      placeholder=""
                      name="confirm_password"
                      value={user.confirm_password}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="uk-width-auto@m">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleConfirmPasswordVisible}
                  >
                    {visibleConfirmPassword ? (
                      <i className="fa fa-eye-slash logineye"></i>
                    ) : (
                      <i className="fa fa-eye logineye"></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="uk-text-center uk-width-1-1@s mrtop30">
              <button
                className="uk-button uk-button-default loginbtn"
                onClick={handleSubmit}
              >
                Register
              </button>
            </div>
            <Link to="/">
              <h4>Login</h4>
            </Link>
          </form>
        </div>
      </div>

      {isLoading && <Loader />}
    </>
  );
};

export default Signup;
