import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ForgotURL } from "../assest/api/BaseURL";
import Logo from "../../images/web/logo2.png";
import "../styles/style.css";

const ForgotPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleInput = (event) => {
    setEmail(event.target.value);
    setError("");
    setSuccess("");
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "") {
      setError("Email should not be empty");
    } else if (!email_pattern.test(email)) {
      setError("Please Enter valid email");
    } else {
      try {
        const response = await axios.post(ForgotURL, { email: email });

        if (response.status === 200) {
          setSuccess("Password Reset Link sent successfully to your email !");
          setEmail("");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setError(error.response.data.message);
          setEmail("");
        } else if (error.response && error.response.status === 402) {
          setError(error.response.data.message);
          setEmail("");
        } else {
          setError(error.message);
          setEmail("");
        }
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="modal_container videoscreen">
        <div className="uk-card uk-card-default uk-card-body logincardcss">
          <div className="uk-text-center">
            <img src={Logo} alt="LOGO" className="login_logo" />
          </div>

          <form className="uk-form-stacked loginform">
            <h3>Forgot Your Password?</h3>

            {error && (
              <div className="uk-alert-danger uk-alert" uk-alert="">
                {error}
              </div>
            )}

            {success && (
              <div className="uk-alert-success uk-alert" uk-alert="">
                {success}
              </div>
            )}

            <div className="uk-margin">
              <label className="uk-form-label">Email</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  type="email"
                  placeholder=""
                  name="email"
                  value={email}
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="uk-text-center uk-width-1-1@s mrtop30">
              <button
                className="uk-button uk-button-default loginbtn"
                onClick={handleForgotPassword}
              >
                Submit
              </button>
            </div>
            <Link to="/">
              <h4>Login</h4>
            </Link>
          </form>
        </div>
      </div>

      {/* <div className="background">
        <div className="container">
          <div className="uk-width-1-1" style={forgotFormStyle}>
            <div className="formcss">
              <div className="uk-width-1-1 headerddiv">
                <div className="uk-grid">
                  <div className="uk-width-auto"></div>
                  <div className="uk-width-expand uk-text-right">
                    <Link to="/">
                      <span className="uk-button custombtn">Login Account</span>
                    </Link>
                  </div>
                </div>

                <img src={loginImg} className="logingif" alt="LoginImg" />
                <p className="smalltxt">
                  We get it, stuff happens. Just enter your email address below
                  and we'll send you a link to reset your password!
                </p>
              </div>
              <h3 className="uk-text-center signinhd">Forgot Your Password?</h3>
              <span className="signinsubhd">Welcome Back</span>

              <form className="uk-form-stacked" method="post" action="">
                <div className="inputdiv">
                  {error && (
                    <div className="uk-alert-danger uk-alert" uk-alert="">
                      {error}
                    </div>
                  )}
                  {success && (
                    <div className="uk-alert-success uk-alert" uk-alert="">
                      {success}
                    </div>
                  )}
                  <div className="uk-margin">
                    <div className="uk-inline">
                      <i className="uk-form-icon fa fa-lock"></i>
                      <input
                        className="uk-input"
                        type="email"
                        name="email"
                        placeholder="Enter Email Address"
                        value={email}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div className="uk-text-center uk-width-1-1@s">
                    <button
                      className="uk-button uk-button-default gbtn uk-margin-remove-top"
                      name="forgot_pass"
                      onClick={handleForgotPassword}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ForgotPage;
